
<div *ngIf="isDataLoaded">
  <app-header></app-header>
</div>
<div id="top" style="padding-top:20px" class="maindivcontainer" *ngIf="isDataLoaded && NominationFormData.length > 0">
  <div>
    <label class="QADSemiboldTitle" style="padding-right: 30px;">{{NominationFormData[0].CertificationLabel}}</label>
    <label class="lblQADFormStatus" [style.background-color]="NominationFormData[0].FormStatusColor">{{NominationFormData[0].FormStatusDesc}}</label>
    <mat-horizontal-stepper #stepper class="QADMediumText" style="display:inline-flex;float:right;margin-top:-21px;">
      <mat-step *ngFor="let item of FormStatusTimeline" [label]="item.TimelineLabel" [state]="item.TimelineDesc" [completed]="item.TimelineFlag"></mat-step>
      <ng-template matStepperIcon="edit">
        <mat-icon></mat-icon>
      </ng-template>

    </mat-horizontal-stepper>
  </div>

  <div id="divgeneralinfo" style="padding-top: 52px;">
    <label class="QADSemiboldTitle">General Information</label>
    <div class="row formdivContainer">
      <div class="col-7" style=" width:60%">
        <div class="row QADMediumFieldLabel" style="padding-top: 10px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;">Nominee's Enterprise ID </label>
          <label class="col-6 col-sm-6 col-lg-6" style="width: 34%; ">Nominator's Enterprise ID(s)</label>
          <!-- <div style="border-left: 3px solid grey;height: 200px;margin-left: 1000px;position: absolute;"> -->
          <!-- </div> -->
        </div>

        <div class="row QADRegularFieldText" style="padding-bottom: 5px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;">{{NominationFormData[0].NomineeEnterpriseId}}</label>
          <label class="col-6 col-sm-6 col-lg-6" *ngIf="!((isAdmin || isReviewer ) && (isEQADDraft && EQADNominateFlag))" style="width: 34%; ">{{NominationFormData[0].NominatorEID }}</label>
          <mat-chip-grid #chipGrid aria-label="Nominator selection">
            <mat-chip-row *ngFor="let item of NominatorEIDs">
              {{item}}
            </mat-chip-row>
          </mat-chip-grid>
          <input *ngIf="((isAdmin || isReviewer) && (isEQADDraft && EQADNominateFlag))" style="max-width: 240px; margin-left: 15px; border: 1px solid #767676; height: 30px; border-radius: 3px; font-size: 14px;font-family: 'Graphik-Regular'; color: #4F4B53; font-weight: 500; padding:3px;" #NominatorInput id="NominatorpeopleEID" [(ngModel)]="NominatorsEnterprise" [formControl]="NominatorCtrl" (ngModelChange)="NominatorChangeFn($event)" [matChipInputFor]="chipGrid" [matAutocomplete]="autos" (keypress)="restrictSpecialChar($event)" (focusin)="onFocusinNominator($event)" (focusout)="onFocusOutNominatorEvent($event)" />
          <label id="lblformwarning" style="width: 220px; margin-left: 53%; color: red;">{{lblWarning}}</label>
          <mat-autocomplete #autos="matAutocomplete" (optionSelected)="selected($event)" (opened)="onAutocompleteOpened()" (closed)="onAutocompleteClosed()">
            <mat-option *ngFor="let items of NominatorEIDData" appen [value]="items.EnterpriseId">
              {{items.EnterpriseId}}
            </mat-option>
          </mat-autocomplete>

        </div>

        <div style="border-top: 1px solid #707070;"></div>

        <div class="row QADMediumFieldLabel" style="padding-top: 10px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;">Nominee's Market</label>
          <label class="col-6 col-sm-6 col-lg-6" style="width: 34%; ">Nominator's Market(s)</label>
        </div>
        <div class="row QADRegularFieldText" style="padding-bottom: 5px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;">{{NominationFormData[0].NomineesMarket}}</label>
          <!--<label class="popuplabelsdata" style="display: inline-block; max-width: 328px; margin-left: -14px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" [matTooltip]="NominatorsMarketToolTip" matTooltipPosition="right">{{lblNominatorMarketUnit}}</label>-->
          <label class="col-6 col-sm-6 col-lg-6" style="width: 34%; ">{{lblNominatorMarket}}</label>
        </div>
        <div style="border-top: 1px solid #707070;"></div>
        <div class="row QADMediumFieldLabel" style="padding-top: 10px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;">Nominee's Market Unit</label>
          <label class="col-6 col-sm-6 col-lg-6" style="width: 34%; ">Market Unit QL Enterprise ID</label>
        </div>
        <div class="row QADRegularFieldText" style="padding-bottom: 10px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;">{{NominationFormData[0].NomineesMarketUnit}}</label>
          <label class="col-6 col-sm-6 col-lg-6" style="width: 34%; ">{{NominationFormData[0].MarketUnitQRLEnterpriseID}}</label>
        </div>
        <div *ngIf="NominationFormData[0].NomineesMarketUnit === 'United States'" class="row QADMediumFieldLabel" style="padding-top: 10px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;" matTooltip="Industry Level 1" 
         [matTooltipPosition]="'above'" matTooltipClass="IStooltip">Nominee's Market Unit - Americas</label>
        </div>
        <div *ngIf="NominationFormData[0].NomineesMarketUnit === 'United States'" class="row QADRegularFieldText" style="padding-bottom: 8px;">
          <label class="col-6 col-sm-6 col-lg-6" style="width:34%;">{{NominationFormData[0].NomineeIndustrySegment}}</label>
        </div>
      </div>
      <div style="border-left: 1px solid #707070;margin-top: 20px;margin-bottom:20px"></div>
      <div class="row" style="width:40%">
        <!--<div class="col-5" style="width:40%;position:absolute">-->
        <!--<div style="border-left: 1px solid #707070; min-height: 200px; margin-top: 20px; position: absolute;"></div>-->
        <label class="QADMediumFieldLabel" style="padding-left: 50px;padding-top: 10px;">Rationale for Nomination</label>
        <div style="padding-left: 50px; margin-bottom: 40px;">
          <textarea class="col-12" id="rationalfornominationTextarea" rows="6" cols="60" maxlength="2000" style="resize:none;padding-left: 4px;"
                    [disabled]="disabledfornominationtextarea" [class.rationalfornominationdisabled]="disabledfornominationtextarea"
                    [(ngModel)]="rationaleforNominationtxt" (keypress)="restrictspecialcharacters($event)">
       </textarea>
          <label class="Rationalelabel" *ngIf="EliteQADFormFlag">
            <span> {{qadRationaleText}}</span>
          </label>
        </div>
        <!--</div>-->
      </div>
    </div>
  </div>

  <label class="QADSemiboldTitle" style="padding-top: 52px;">Areas of Expertise & Skills</label>
  <div id="divAOE&Skills" class="formdivContainer row">
    <div id="divAOE" class="col-12" style="padding-top: 10px;">
      <label class="QADMediumFieldLabel">Areas of Expertise</label>

      <!--<div class="checkbox-group QADRegularFieldText">
        <label *ngFor="let item of AOEData" [for]="item.AreaOfExpertiseId">
          <input type="checkbox" [(ngModel)]="item.AOECheckInd" [id]="item.AreaOfExpertiseId" [disabled]="!ReadonlyNomineeSkillsnAOE()" required>
          {{ item.AreasOfExpertiseNm }}
        </label>
      </div>-->
      <div class="checkbox-group col-12 QADRegularFieldText" style="margin-left:-25px;top:8px">
        <div *ngFor="let item of AOEData" style="display:inline-flex">
          <div matTooltip="Please contact Security.Quality@accenture.com to add this Area of Expertise" matTooltipClass="AOEtooltip" [matTooltipPosition]="'above'" [matTooltipDisabled]="!((isDraft || isQADCertified || isEQADCertified || isReadyForQLReview || isReadyForSCReview || isEQADReadyForQLReview || isEQADReadyForSCReview ) && item.AreasOfExpertiseNm.includes('Security') )">
            <mat-checkbox [(ngModel)]="item.AOECheckInd" [id]="item.AreaOfExpertiseId" class="AOECheckbox" [disabled]="!ReadonlyNomineeSkillsnAOE() || item.DisableSecAoEFlag " required> 
              {{ item.AreasOfExpertiseNm }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>

     <div id="divSkills" class="col-12" style="padding-bottom: 10px; padding-top: 15px;">
      <div class="modal-container" id="container" (click)="$event.stopPropagation()">
        <ng-template #skillcontent let-modal style="position:absolute;margin-left:-500px" (click)="$event.stopPropagation()">
          <div class="modal-header" (click)="$event.stopPropagation()">
            <h4 class="modal-title" id="modal-basic-title">Add Skills</h4>
            <button mat-icon-button aria-label="Close" (click)="closeSkillPopupDialogue()" class="close" style="color: black; outline: none !important">
              <mat-icon>close</mat-icon>
            </button>
            <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>-->
          </div>
          <div class="modal-body" id="skillmodel" (click)="$event.stopPropagation()">

            <div style='padding-left:12px;padding-bottom: 10px;'>
              <input #input type='text' minlength="2" matInput [(ngModel)]="skillsSearchString" (input)="skillsFilterNodes($any($event.target).value)" (click)="onskillsSearchtxtboxClick($event)" placeholder='Type and Search' style='padding:5px 5px 5px 5px;width:485px;border:1px solid #837F89' />
              <!--<input #input type='text' minlength="2" matInput (input)="filterNodes($any($event.target).value)" [(ngModel)]="skillsSearchString" placeholder='Type and Search' style='padding:5px 5px 5px 5px;width:485px' />-->

            </div>
            <br />
            <mat-tree [dataSource]="skillsdataSource" [treeControl]="skillstreeControl" class="skill-tree">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                <!--[style.display]=" this.skillsSearchString && hideLeafNode(node) ? 'none' : 'block'" >-->

                <li class="mat-tree-node">
                  <div >
                  <!-- <div matTooltip="Please contact Security.Quality@accenture.com to add this Skill" matTooltipClass="securitytooltiponskills" [matTooltipPosition]="'left'" [matTooltipDisabled]="!((isNominee && isDraft) || node.disableSecSkillsFlag)" style="z-index: 2000;"> -->
                    <mat-checkbox class="checklist-leaf-node"
                                  (change)="skillsitemToggle($event.checked, node)"
                                  [(ngModel)]="node.isChecked" [(indeterminate)]="node.indeterminate" [disabled]="node.disableSecSkillsFlag" >
                                  {{node.skillsExpertiseNm}}
                    </mat-checkbox>
                  <!-- </div> -->
                  </div>
                </li>

              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle>

                <li>
                  <div class="mat-tree-node">
                    <div >
                    <!-- <div matTooltip="Please contact Security.Quality@accenture.com to add this Skill" matTooltipClass="securitytooltiponskills" [matTooltipPosition]="'left'" [matTooltipDisabled]="!((isNominee && isDraft) || node.disableSecSkillsFlag)" style="z-index: 1000;"> -->
                    <mat-checkbox  [(ngModel)]="node.isChecked" [(indeterminate)]="node.indeterminate"
                                  (change)="skillsitemToggle($event.checked, node)" (click)="$event.stopPropagation()" [disabled]="node.disableSecSkillsFlag" required>
                                  {{node.skillsExpertiseNm}}
                    </mat-checkbox>               
                    </div> 
                    <!-- </div> -->
                    <button mat-icon-button matTreeNodeToggle style="display:contents;right:60px;position:absolute"
                            [attr.aria-label]="'toggle ' + node.skillsExpertiseNm">
                      <mat-icon class="mat-icon-rtl-mirror" *ngIf="skillstreeControl.isExpanded(node)" style="opacity:0.6">
                        expand_more
                      </mat-icon>
                      <mat-icon class="mat-icon-rtl-mirror" *ngIf="!skillstreeControl.isExpanded(node)" style="opacity:0.6">
                        chevron_right
                      </mat-icon>

                    </button>

                  </div>

                  <ul [class.skill-tree-invisible]="!skillstreeControl.isExpanded(node)" class="nested-node" style="padding-left:15px">
                    <!--<ul [class.skill-tree-invisible]="!skillstreeControl.isExpanded(node)">-->
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </mat-tree>
          </div>
        </ng-template>
      </div>
      <div id="btn-skillscontainer">
        <span class="QADMediumFieldLabel">Skills</span>
        <img [src]="SkillsClickicon" id="skill-icon" matTooltip="Please contact Security.Quality@accenture.com to add the Security skill" matTooltipPosition="above" matTooltipClass="skillstooltip" [matTooltipDisabled]="!((isDraft || isQADCertified || isEQADCertified || isReadyForQLReview || isReadyForSCReview  || isEQADReadyForQLReview || isEQADReadyForSCReview ))" class="SkillsOpenicon" (click)="openSkilldialog(skillcontent,$event)" *ngIf="ReadonlyNomineeSkillsnAOE()" />
        <div id="skillsSelectedName">
          <ng-template let-content ngFor [ngForOf]="skillsCheckedNodes">
            <div class="selectedSkillsNm" id={{content.id}}>
              <span class="skillName">{{content.skillsExpertiseNm}}</span>
              <span class="clearbutton">

                <img style="width: 20px; height: 20px;" (click)="uncheckSkillsNode(content.skillsExpertiseNm)" src="assets/img/NominationForm/clear_purple.svg" *ngIf="ReadonlyNomineeSkillsnAOE()" />

              </span>
            </div>
          </ng-template>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="isAdmin || isReviewer || isReadOnly">
    <label class="QADSemiboldTitle" style="padding-top: 52px;">SteerCo Review - For Admin Use Only</label>
    <div id="divstreecoReview" class="row formdivContainer">
      <div class="col-12" style="padding-top: 10px;padding-bottom:10px">
        <div class="row QADMediumFieldLabel">
          <label class="col-4 col-sm-4 col-lg-4 " style="width:34%;">SteerCo Review Date</label>
          <label class="col-4 col-sm-4 col-lg-4" style="width: 34%; ">Reconsider Date</label>
          <label class="col-4 col-sm-4 col-lg-4" style="width: 34%; ">SteerCo Decision</label>
        </div>
        <div class="row QADRegularFieldText">
          <div class="col-4 col-sm-4 col-lg-4 commondisableddate">
            <ng-container *ngIf="isAdmin && !isReactivebtnVisible ; else readonlyreviewdate">
              <mat-form-field style="width:95%">
                <mat-label></mat-label>
                <input matInput [matDatepicker]="reviewdtpicker" [(ngModel)]="SCReviewDate" (change)="onDateChange($event, 'reviewdtpicker')" [disabled]="disablesteercoDateandDecison" [min]="datePickerYear()">
                <mat-datepicker-toggle matIconSuffix [for]="reviewdtpicker"></mat-datepicker-toggle>
                <mat-datepicker #reviewdtpicker [disabled]="disablesteercoDateandDecison"></mat-datepicker>
              </mat-form-field>
            </ng-container>
            <ng-template #readonlyreviewdate>
              <input type="text" [(ngModel)]="SCReviewDate" id="SCReviewDt" class="textbox QADRegularFieldText" disabled style="padding-left: 23px; width: 95%;" />
            </ng-template>
          </div>
          <div class="col-4 col-sm-4 col-lg-4 commondisableddate">
            <ng-container *ngIf="isAdmin && !isReactivebtnVisible; else readonlyreconsiderdt">
              <mat-form-field style="width:95%">
                <input matInput [matDatepicker]="reconsiderdtpicker" [disabled]="Streecocontroldisabled" [(ngModel)]="SCReconsiderDate" (change)="onDateChange($event, 'reconsiderdtpicker')" [min]="datePickerYear()">
                <mat-datepicker-toggle matIconSuffix [for]="reconsiderdtpicker"></mat-datepicker-toggle>
                <mat-datepicker #reconsiderdtpicker [disabled]="Streecocontroldisabled"></mat-datepicker>
              </mat-form-field>
            </ng-container>
            <ng-template #readonlyreconsiderdt>
              <input type="text" [(ngModel)]="SCReconsiderDate" id="SCReconsiderDt" class="textbox QADRegularFieldText" disabled style="padding-left: 23px; width: 95%;" />
            </ng-template>
          </div>
          <div class="col-4 col-sm-4 col-lg-4">
            <ng-container *ngIf=" (isAdmin && !isReactivebtnVisible && !disablesteercoDateandDecison); else readonlyStreeCoDecisions">
              <mat-form-field style="width:95%" id="scdecision">
                <mat-icon class="expand_icon">expand_more</mat-icon>
                <mat-select [(ngModel)]="StreeCoDecisionsselected" (selectionChange)="onStreeCoDecisionsSelected($event)" disableRipple>
                  <mat-option *ngFor="let option of drpdwnStreeCoDecisions" id="option.SCDecisionCd" [value]="option.SCDecisionCd">{{ option.SCDecisionDesc }}</mat-option>
                </mat-select>
              </mat-form-field>
            </ng-container>
            <ng-template #readonlyStreeCoDecisions>
              <input type="text" [(ngModel)]="StreeCoDecisionDesc" [id]="StreeCoDecisionsselected" class="textbox QADRegularFieldText" disabled style="padding-left: 23px; width: 95%;" />
            </ng-template>
            <!--<input type="text" class="textbox QADRegularFieldText" style="padding-left: 28px; width: 95%;" [disabled]="Streecocontroldisabled" />-->
          </div>
        </div>
        <label class="QADMediumFieldLabel" style="padding-top: 10px;">Notes</label>
        <div class="row QADRegularFieldText" style="padding-left: 17px">

          <textarea id="adminNotes" rows="3" style="width: 97.5%; resize: none" maxlength="2000"
                    [disabled]="!isAdmin  || isReactivebtnVisible"
                    [(ngModel)]="steercoNotes">
  </textarea>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isAdmin || isReviewer || isReadOnly || isNominee">
    <label class="QADSemiboldTitle" style="padding-top: 52px;"> Credentialing Status - For Admin Use Only </label>
    <div id="divcredentialingstatus" class="row formdivContainer">
      <div class="col-12">
        <div class="row QADMediumFieldLabel" style="padding-top: 10px;">
          <label class="col-4 col-sm-4 col-lg-4" style="width:34%;">Training Enrollment Date</label>
          <label class="col-4 col-sm-4 col-lg-4" style="width: 34%; ">Training Completion Date</label>
          <label class="col-4 col-sm-4 col-lg-4" style="width: 34%; ">Mastery Test Completion Date</label>
        </div>
        <div class="row QADRegularFieldText">
          <div class="col-4 col-sm-4 col-lg-4">
            <input type="text" class="textbox" disabled [value]="NominationFormData[0].TrainingEnrollmentDt" style="padding-left: 23px; width: 95%;" />
          </div>
          <div class="col-4 col-sm-4 col-lg-4">
            <input type="text" class="textbox" disabled [value]="NominationFormData[0].TrainingCompletionDt" style="padding-left: 23px; width: 95%;" />
          </div>
          <div class="col-4 col-sm-4 col-lg-4">
            <input type="text" class="textbox" disabled [value]="NominationFormData[0].MasteryTestCompletionDt" style="padding-left: 23px; width: 95%;" />
          </div>
        </div>
        <div class="row QADMediumFieldLabel" style="padding-top: 10px;">
          <label class="col-4 col-sm-4 col-lg-4" style="width: 34%;">Training Session Date</label>
          <label [hidden]="!QADFormFlag" class="col-4 col-sm-4 col-lg-4" style="width: 34%;">Training Status</label>
          <label class="col-4 col-sm-4 col-lg-4" style="width: 34%;">Registration Cancelled Date</label>
          <div class="col-4 col-sm-4 col-lg-4" display="none" style="width:95%">
            <label [hidden]="!EliteQADFormFlag">Case Study Assessment Completion Date</label>
          </div>
        </div>




        <div class="row QADRegularFieldText" style="padding-bottom:10px">
          <div class="col-4 col-sm-4 col-lg-4">
            <input type="text" class="textbox" disabled style="padding-left: 23px; width: 95%;" [(ngModel)]="NominationFormData[0].SessionDate" />
          </div>
          <div class="col-4 col-sm-4 col-lg-4" [hidden]="!QADFormFlag" >
            <input [hidden]="!QADFormFlag" type="text" class="textbox" disabled style="padding-left: 23px; width: 95%;" [(ngModel)]="NominationFormData[0].TrainingStatus" />
          </div>
          <div class="col-4 col-sm-4 col-lg-4 commondisableddate">
            <ng-container *ngIf="(isAdmin && !isReactivebtnVisible) ; else readonlyCredentialCancel">
              <mat-form-field style="width:95%">
                <input matInput [matDatepicker]="CredentialCancelpicker" (change)="onDateChange($event, 'CredentialCancelpicker')" (dateInput)="registerationCanceldateinputEvent('input', $event)" [(ngModel)]="RegistrationCanceledDt" [disabled]="registerationCanceldtdisable" [min]="datePickerYear()">

                <mat-datepicker-toggle matIconSuffix [for]="CredentialCancelpicker"></mat-datepicker-toggle>
                <mat-datepicker #CredentialCancelpicker [disabled]="registerationCanceldtdisable"></mat-datepicker>
              </mat-form-field>
            </ng-container>
          </div>
          <div class="col-4 col-sm-4 col-lg-4">
            <input [hidden]="!EliteQADFormFlag" type="text" class="textbox" disabled style="padding-left: 23px; width: 95%;" [(ngModel)]="NominationFormData[0].CaseStudyAssessmentCompletionDt" />
          </div>
          <ng-template #readonlyCredentialCancel>
            <input type="text" [(ngModel)]="RegistrationCanceledDt" id="CredentialCancelDt" class="textbox QADRegularFieldText" disabled style="padding-left: 23px; width: 95%;" />
          </ng-template>
          <!-- <textarea name="Text1" cols="40" rows="5">rfvreget</textarea> -->
        </div>
        <div class="row QADMediumFieldLabel" style="padding-top: 10px;">
        <label [hidden]="!EliteQADFormFlag" class="col-4 col-sm-4 col-lg-4" style="width: 34%;">Training Status</label>
      </div>
        <div class="row QADRegularFieldText" style="padding-bottom:10px">
          <div class="col-4 col-sm-4 col-lg-4">
            <input [hidden]="!EliteQADFormFlag" type="text" class="textbox" disabled style="padding-left: 23px; width: 95%;" [(ngModel)]="NominationFormData[0].TrainingStatus" />
          </div>
        </div>
      </div>
      </div>
  </div>
  <div>
    <span *ngIf="(isReadyForQLReview || isReadyForSCReview || isReadyForCredentialing  || isQADCertified) && !EliteQADFormFlag">  <label class="QADMediumFieldLabel" style="padding-top:18px"><span style="padding-right:6px">Form Submitted On:</span>{{NominationFormData[0].SubmitDttm}}</label><br /></span>
    <span *ngIf="(isReadyForSCReview || isReadyForCredentialing || isQADCertified) && !EliteQADFormFlag"> <label class="QADMediumFieldLabel" style="padding-top:15px"><span style="padding-right:6px">QL Approved On:</span>{{NominationFormData[0].QRLApprovedDttm}}</label><br /></span>
    <span *ngIf="isEQADDraft || isQADCertified || isEQADReadyForQLReview || isEQADReadyForSCReview || isEQADReadyForCertification || isEQADCertified"> <label class="QADMediumFieldLabel" style="padding-top:15px"><span style="padding-right:6px">QAD Certified On:</span>{{NominationFormData[0].QADCertificationDt}}</label><br /></span>
    <span *ngIf="isEQADReadyForQLReview || isEQADReadyForSCReview || isEQADReadyForCertification|| isEQADCertified">  <label class="QADMediumFieldLabel" style="padding-top:15px"><span style="padding-right:6px">EQAD Form Submitted On:</span>{{NominationFormData[0].SubmitDttm}}</label><br /></span>
    <span *ngIf="isEQADReadyForSCReview || isEQADReadyForCertification || isEQADCertified"><label class="QADMediumFieldLabel" style="padding-top:15px"><span style="padding-right:6px">QL Approved On:</span>{{NominationFormData[0].QRLApprovedDttm}}</label><br /></span>
    <span *ngIf="isEQADCertified"><label class="QADMediumFieldLabel" style="padding-top:15px"><span style="padding-right:6px">EQAD Certified On:</span>{{NominationFormData[0].EQADCertificationDt}}</label><br /></span>


    <span *ngIf="!isReactivebtnVisible"><label class="QADMediumFieldLabel" style="padding-top:15px"><span style="padding-right:6px">Registration Cancelled Date:</span>{{NominationFormData[0].RegistrationCanceledDtBottomDisplay}}</label><br /></span>
    <span *ngIf="!isReactivebtnVisible"><label class="QADMediumFieldLabel" style="padding-top:15px"><span style="padding-right:6px">Reactivate Date:</span>{{NominationFormData[0].ReactivateDttm}}</label></span>


  </div>
  <div style="padding-bottom: 80px;padding-top:18px">
    <label class="QADSemiboldTitle" *ngIf="isNominee && QADFormFlag">
      <span style="color: #A100FF">Note: </span>Time Commitment Required as a QAD: 10-20%.
    </label>
    <!--<button id="Submit" *ngIf="isDraft" class="QADButton" type="button" [disabled]="!isSubmitbtnEnabled()" [title]="isSubmitbtnEnabled() ? '' : submitbtntooltip" (click)="submitNomineeForm()" style="background-color: #a100ff; color: #fff; cursor: pointer;margin-right:30px; ">Submit</button>-->
    <button id="btnEQAD" mat-raised-button *ngIf="isNominateForEQADbtnVisible && !isReactivebtnVisible" class="QADButton" type="button" [disabled]="isNominateforEqadbtnDisabled()" [matTooltip]="NomimateEQADbtntooltip" matTooltipPosition="above" (click)="NominateEQADForm()" style="background-color: #a100ff; color: #fff; cursor: pointer;margin-right:30px; ">Nominate for EQAD</button>
    <button id="btnSubmit" mat-raised-button *ngIf="isSubmitbtnVisible" class="QADButton" type="button" [disabled]="isSavesbmitbtnEnabled() || isSaveSubmitbtnEnabled()" [matTooltip]="submitbtntooltip" matTooltipPosition="above" (click)="submitNomineeForm(validationModel)" style="background-color: #a100ff; color: #fff; cursor: pointer;margin-right:30px;">Submit</button>
    <button id="btnNoupdatesneeded" mat-raised-button *ngIf="isNoupdatesneededbtnVisible" class="QADButton" type="button" (click)="noUpdatesNeededNomineeForm()" style="background-color: #a100ff; color: #fff; cursor: pointer;margin-right:30px;">No updates needed</button>
    <button id="btnApprove" mat-raised-button *ngIf="isApprovebtnVisible" class="QADButton" type="button" [disabled]="isApprovebtnDisabled()" [matTooltip]="approvebtntooltip" [matTooltipClass]="btnapprovetooltipClass" matTooltipPosition="above" (click)="approveNomineeForm()" style="background-color: #a100ff; color: #fff; cursor: pointer;margin-right:30px; ">Approve</button>
    <button id="btnSave" mat-raised-button *ngIf="isSavebtnVisible" class="QADButton" type="button" [disabled]="isSavesbmitbtnEnabled() || isSaveSubmitbtnEnabled()" [matTooltip]="savebtntooltip" matTooltipPosition="above" (click)="saveNomineeForm()" style="background-color: #a100ff; color: #fff; cursor: pointer;margin-right:30px; ">Save</button>
    <button id="btnReactivate" mat-raised-button *ngIf="isReactivebtnVisible" class="QADButton" type="button" (click)="saveNomineeForm()" style="background-color: #a100ff; color: #fff; cursor: pointer;margin-right:30px; ">Reactivate</button>
  </div>
  <ng-template #validationModel let-modal>
    <div class="modal-body">
      <p style="font-size: 30px; font-family: Graphik; font-weight: 600; color: #000000;">Your form has been successfully submitted.</p>

      <button mat-button class="validationQADButton" (click)="closeSubmitValidationDialog()">OK</button>
    </div>
  </ng-template>

  <div class="loading-overlay" *ngIf="isLoading">
    <div class="loading-spinner"></div>
    <div style=" padding-left: 6px;">Loading...</div>
  </div>

</div>

<div style=" margin-top: 35px !important; margin-left: 10px !important; font-size: 20px !important;" *ngIf="!isDataLoaded">{{invalidURLtxt}}</div>
<a id="btnlogout" href="javascript:void(0)" style="display: none;" (click)="applicationLogout()">Logout</a>
<div *ngIf="isDataLoaded">
  <app-footer></app-footer>
</div>


